import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Votes`}</h1>
    <ul>
      <li parentName="ul">{`The entire `}<a parentName="li" {...{
          "href": "/polis-opinion-matrix",
          "title": "polis opinion matrix"
        }}>{`polis opinion matrix`}</a>{`, consisting of the actions `}<a parentName="li" {...{
          "href": "/Agree",
          "title": "Agree"
        }}>{`Agree`}</a>{`, `}<a parentName="li" {...{
          "href": "/disagree",
          "title": "disagree"
        }}>{`disagree`}</a>{` and `}<a parentName="li" {...{
          "href": "/pass",
          "title": "pass"
        }}>{`pass`}</a>{` in the `}<a parentName="li" {...{
          "href": "/Participation-Interface",
          "title": "Participation Interface"
        }}>{`Participation Interface`}</a>{``}
        <ul parentName="li">
          <li parentName="ul">{`A structured reply to each comment is necessary to gather the `}<a parentName="li" {...{
              "href": "/polis-opinion-matrix",
              "title": "polis opinion matrix"
            }}>{`polis opinion matrix`}</a>{`. Gathering a binary matrix (1, 0, -1) is intentional, and specifically tailored to the `}<a parentName="li" {...{
              "href": "/Algorithms",
              "title": "👾 Algorithms"
            }}>{`👾 Algorithms`}</a>{` `}<a parentName="li" {...{
              "href": "/Polis",
              "title": "Polis"
            }}>{`Polis`}</a>{` employs.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      